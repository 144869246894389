import { Button, type ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';

type VeranstaltungEventButtonProps = Pick<ButtonProps, 'children'> & {
  readonly eventColor: string;
  readonly themaSapId?: number | null;
  readonly veranstaltungId?: string | null;
};

export const VeranstaltungEventButton: React.FC<VeranstaltungEventButtonProps> = ({ themaSapId, veranstaltungId, eventColor, children }: VeranstaltungEventButtonProps) => (
  <Button
    component={Link}
    to={`/thema/${themaSapId}/veranstaltung/${veranstaltungId}`}
    target="_blank"
    variant="contained"
    size="small"
    sx={{ borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: eventColor }}
  >
    {children}
  </Button>
);
