import { type AblaufTag, ressourcenBlockungTypes } from '../../../../../../dtos';
import { type CalendarEvent, isExperteEvent, isRaumEvent, isSlotEvent } from './CalendarEvent';
import { type CalendarResource, isExperteResource, isRaumResource, isSlotResource } from './CalendarResource';
import {
  addDays,
  areIntervalsOverlapping,
  compareAsc,
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  isWeekend,
  isWithinInterval,
  max,
  min,
  set,
  startOfDay,
  subDays,
} from 'date-fns';
import { v4 } from 'uuid';

type Actions = {
  shift: boolean;
  create: boolean;
  switchRaum: boolean;
};

export class CalendarEventHelper {
  private readonly ablauf: AblaufTag[];

  public constructor(ablauf: AblaufTag[]) {
    this.ablauf = ablauf;
    if (ablauf.length === 0) {
      const date = new Date();
      const startTime = { hours: 9, minutes: 0, seconds: 0 };
      const endTime = { hours: 17, minutes: 0, seconds: 0 };
      this.ablauf.push({ start: set(date, startTime), end: set(date, endTime) });
    }

    this.ablauf.sort((a, b) => compareAsc(a.start, b.start));
  }

  public initEvents(resources: CalendarResource[]): CalendarEvent[] {
    const newEvents = [];

    for (const resource of resources) {
      newEvents.push(...resource.toEvents());
    }

    this.copyOldSelectionAsCurrentSelection(newEvents);

    return newEvents;
  }

  public addEvent(resource: CalendarResource, events: CalendarEvent[], date: Date): CalendarEvent[] | Error {
    const currentSelection = events.filter((event) => event.isCurrentSelection);
    const fixedEvents = events.filter((event) => !event.isCurrentSelection);

    const actions = this.deduceActions(resource, currentSelection, date);

    if (actions.shift) {
      this.shiftSelection(currentSelection, date);
    }

    if (actions.switchRaum) {
      this.switchRaum(resource, currentSelection);
    }

    if (actions.create) {
      this.addEventToSelection(resource, date, currentSelection);
    }

    let err = this.checkIncludesWeekend(currentSelection);
    if (err) {
      return err;
    }

    err = this.checkAblaufOverflow(currentSelection);
    if (err) {
      return err;
    }

    err = this.checkRaumAvailability(resource, currentSelection);
    if (err) {
      return err;
    }

    err = this.checkEventCollisions(currentSelection, fixedEvents);
    if (err) {
      return err;
    }

    return [...currentSelection, ...fixedEvents];
  }

  public removeEvent(eventId: string, events: CalendarEvent[]): CalendarEvent[] {
    return events.filter((event) => (event.isCurrentSelection ? event.id !== eventId : true));
  }

  private deduceActions(resource: CalendarResource, selection: CalendarEvent[], date: Date): Actions {
    const resourceSelection = selection.filter((event) => event.resource === resource.id);

    const actions: Actions = {
      shift: false,
      create: true,
      switchRaum: false,
    };

    if (selection.length === 0) {
      return actions;
    }

    const ablaufDayCnt = this.ablauf.length;

    const selectionDates = this.eachDayOfSelection(selection);
    const isOutsideSelection = isBefore(date, selectionDates[0]) || isAfter(date, selectionDates[selectionDates.length - 1]);

    const resourceSelectionExhaustive = this.eachDayOfSelection(resourceSelection).length === ablaufDayCnt;
    const selectionExhaustive = this.eachDayOfSelection(selection).length === ablaufDayCnt;

    const isRaumOrSlot = resource.type === ressourcenBlockungTypes.RAUM || resource.type === ressourcenBlockungTypes.STANDORT;
    const raumSelected = isRaumOrSlot && selection.some((event) => event.type === ressourcenBlockungTypes.RAUM || event.type === ressourcenBlockungTypes.STANDORT);
    const isSelected = resourceSelection.some((event) => isWithinInterval(date, event));

    if (isOutsideSelection && selectionExhaustive) {
      actions.create = false;
      actions.shift = true;
    }

    if (resourceSelectionExhaustive) {
      actions.create = false;
      actions.shift = true;
    }

    if (raumSelected) {
      actions.create = false;
      actions.shift = true;
      actions.switchRaum = true;
    }

    if (isSelected) {
      actions.create = false;
      actions.shift = true;
    }

    return actions;
  }

  private shiftSelection(selection: CalendarEvent[], date: Date): void {
    for (let idx = 0; idx < selection.length; idx += 1) {
      const event = { ...selection[idx] };

      const diff = differenceInDays(startOfDay(date), startOfDay(event.start));
      event.start = addDays(event.start, diff);
      event.end = addDays(event.end, diff);

      selection.splice(idx, 1, event);
    }
  }

  private switchRaum(resource: CalendarResource, selection: CalendarEvent[]): void {
    if (isExperteResource(resource)) {
      return;
    }

    for (let idx = 0; idx < selection.length; idx += 1) {
      if (isExperteEvent(selection[idx])) {
        continue;
      }

      const { start } = selection[idx];
      const event = resource.createEvent(start);
      event.isCurrentSelection = true;
      selection.splice(idx, 1, event);
    }
  }

  private eachDayOfSelection(selection: CalendarEvent[]): Date[] {
    const days = selection.flatMap((event) => [event.start, event.end]);
    if (!days.length) {
      return [];
    }

    const start = min(days);
    const end = max(days);

    return eachDayOfInterval({ start, end });
  }

  private addEventToSelection(resource: CalendarResource, date: Date, selection: CalendarEvent[]): void {
    const start = startOfDay(date);
    const end = endOfDay(date);
    const eventIdx = selection.findIndex((event) => event.resource === resource.id && this.isAdjactentEvent(event, date));
    if (eventIdx >= 0) {
      const event = { ...selection[eventIdx] };
      event.start = min([event.start, start]);
      event.end = max([event.end, end]);
      event.data = { ...event.data, ablauf: [...event.data.ablauf, { start, end }] };

      selection.splice(eventIdx, 1, event);
    } else {
      const newEvent = resource.createEvent(start, end);
      newEvent.isCurrentSelection = true;

      selection.push(newEvent);
    }
  }

  private checkIncludesWeekend(selection: CalendarEvent[]): Error | null {
    const includesWeekend = this.eachDayOfSelection(selection).some((day) => isWeekend(day));
    if (includesWeekend) {
      return new Error('Planung enthält ein Wochenende');
    }

    return null;
  }

  private checkAblaufOverflow(selection: CalendarEvent[]): Error | null {
    const dates = this.eachDayOfSelection(selection);
    const exceedsDayCnt = dates.length > this.ablauf.length;

    if (exceedsDayCnt) {
      return new Error('Auswahl würde die Anzahl an Tagen im Ablauf übersteigen.');
    }

    return null;
  }

  private isAdjactentEvent(event: CalendarEvent, date: Date): boolean {
    return isSameDay(date, subDays(event.start, 1)) || isSameDay(date, addDays(event.end, 1));
  }

  private checkEventCollisions(selection: CalendarEvent[], fixedEvents: CalendarEvent[]): Error | null {
    const isSelectionBlocked = selection.some((event) =>
      fixedEvents.some((fixedEvent) => {
        if (event.resource !== fixedEvent.resource) {
          return false;
        }

        if (fixedEvent.isOldSelection) {
          return false;
        }

        return areIntervalsOverlapping(event, fixedEvent);
      }),
    );

    if (isSelectionBlocked) {
      return new Error('Auswahl kollidiert mit bestehenden Events.');
    }

    return null;
  }

  private checkRaumAvailability(calendarResource: CalendarResource, selection: CalendarEvent[]): Error | null {
    for (const event of selection) {
      if (isRaumResource(calendarResource) && isRaumEvent(event) && !calendarResource.isAvailable(event)) {
        return new Error('Raum ist für die gewählte Zeit nicht eingekauft.');
      }

      if (isSlotResource(calendarResource) && isSlotEvent(event) && !calendarResource.isAvailable(event)) {
        return new Error('Slot ist für die gewählte Zeit nicht verfügbar.');
      }
    }

    return null;
  }

  private copyOldSelectionAsCurrentSelection(events: CalendarEvent[]): void {
    const hasSelections = events.some((event) => event.isCurrentSelection);
    if (hasSelections) {
      return;
    }

    for (const event of events) {
      if (event.isOldSelection) {
        events.push({ ...event, id: v4(), isOldSelection: false, isCurrentSelection: true });
      }
    }
  }
}
