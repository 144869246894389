import { formatTimeRangeForDate } from '../../../../../../utils/dateFormat';
import { type CalendarExperteEvent, type CalendarRaumEvent } from '../helperClasses';
import { EventButton } from './EventButton';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

type ExternalSAPEventProps = {
  readonly calendarEvent: CalendarRaumEvent | CalendarExperteEvent;
  readonly color: string;
};

export const ExternalSAPEvent: React.FC<ExternalSAPEventProps> = ({ calendarEvent, color }: ExternalSAPEventProps) => (
  <Tooltip
    title={
      <Stack>
        <Typography variant="body2">AKA-Veranstaltung-ID: {calendarEvent.data.quelleTerminId}</Typography>
        <Typography variant="body2">Geschäftsbereich: {calendarEvent.data.division}</Typography>
        <Typography variant="body2">Ablaufzeiten:</Typography>
        {calendarEvent.data.ablauf.map(({ start, end }) => (
          <Typography key={Number(start)} variant="body2" paddingLeft={1}>
            {formatTimeRangeForDate(start, end)}
          </Typography>
        ))}
        <Typography variant="body2">Achtung: Eintrag wurde in SAP gepflegt</Typography>
      </Stack>
    }
  >
    <Box>
      <EventButton eventColor={color} sx={{ cursor: 'default' }}>
        <Stack>
          <Typography variant="body2" textAlign="center" textOverflow="ellipsis" overflow="hidden">
            {calendarEvent.data.quelleTerminId}
          </Typography>
          <Typography variant="caption" fontSize=".5rem">
            {calendarEvent.data.division}
          </Typography>
        </Stack>
      </EventButton>
    </Box>
  </Tooltip>
);
