import { type Veranstaltung } from '../dtos';

const getSlot = (veranstaltung: Veranstaltung) => veranstaltung.standortSlotBlockungen.at(0)?.standortSlot;

const getOrt = (veranstaltung: Veranstaltung) => veranstaltung.raumBlockungen.at(0)?.raum.ort;

export const getSlotBezeichnung = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);

  return slot ? `Slot ${slot.id}` : undefined;
};

export const getOrtBezeichnung = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.name ? ort.name : (slot?.standort.name ?? undefined);
};

export const getOrtKuerzel = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.kuerzel ?? slot?.standort.kuerzel ?? undefined;
};

export const getStandortName = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.name ?? slot?.standort.name ?? undefined;
};

export const getStandortKuerzel = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.kuerzel ?? slot?.standort.kuerzel ?? undefined;
};

export const getRegionName = (veranstaltung: Veranstaltung): string | undefined => {
  const slot = getSlot(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.region?.name ?? slot?.standort.region?.name ?? undefined;
};
