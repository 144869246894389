import Reroute from './components/general/Reroute';
import { AuthRedirectPage } from './pages/AuthRedirectPage';
import { RouterError } from './pages/ErrorPage';
import { FehlerPage } from './pages/fehler/FehlerPage';
import { HomePage } from './pages/HomePage';
import { LayoutWithAuth } from './pages/LayoutWithAuth';
import { LogoutPage } from './pages/LogoutPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { AppointmentCheckPage } from './pages/reporting/AppointmentCheckPage';
import { ThemaSearchPage } from './pages/search/ThemaSearchPage';
import { VeranstaltungPlanungPage } from './pages/thema/planung/VeranstaltungsplanungPage';
import { ThemaPage } from './pages/thema/ThemaPage';
import { VeranstaltungDetailsPage } from './pages/veranstaltung/VeranstaltungDetailsPage';
import { createBrowserRouter } from 'react-router-dom';

const PATH = {
  MAIN_PAGE: '',
  OTHER_PAGE: 'other',
  NOTFOUND: '*',
  SEARCH: {
    THEMA: 'search/thema',
  },
  REROUTE: {
    DEEPLINK: 'va/:veranstaltungId',
  },
  VERANSTALTUNG: {
    DETAILS: 'thema/:themaSapId/veranstaltung/:veranstaltungId',
  },
  THEMA: {
    DETAIL: 'thema/:themaSapId',
    VERANSTALTUNGSPLANUNG: 'thema/:themaSapId/veranstaltung/:veranstaltungsId/planung',
  },
  AUTH: {
    REDIRECT: 'auth/redirect',
    LOGOUT: 'auth/logout',
  },
  FEHLER: 'fehler',
  REPORTING: {
    APPOINTMENT_CHECK: 'reporting/appointmentCheck',
  },
} as const;

export const appRouter = createBrowserRouter(
  [
    {
      path: PATH.AUTH.REDIRECT,
      errorElement: <RouterError />,
      Component: AuthRedirectPage,
    },
    {
      path: PATH.AUTH.LOGOUT,
      errorElement: <RouterError />,
      Component: LogoutPage,
    },
    {
      path: '/',
      element: <LayoutWithAuth />,
      errorElement: <RouterError />,
      children: [
        // General Routes
        {
          Component: HomePage,
          path: PATH.MAIN_PAGE,
        },
        {
          Component: Reroute,
          path: PATH.REROUTE.DEEPLINK,
        },
        // Search Routes
        {
          Component: ThemaSearchPage,
          path: PATH.SEARCH.THEMA,
        },
        // Veranstaltung Routes
        {
          Component: VeranstaltungDetailsPage,
          path: PATH.VERANSTALTUNG.DETAILS,
        },
        // Thema Routes
        {
          Component: ThemaPage,
          path: PATH.THEMA.DETAIL,
        },
        {
          Component: VeranstaltungPlanungPage,
          path: PATH.THEMA.VERANSTALTUNGSPLANUNG,
        },
        // Va Status Dashboard
        {
          Component: FehlerPage,
          path: PATH.FEHLER,
        },
        // System Routes: like 404
        {
          Component: NotFoundPage,
          path: PATH.NOTFOUND,
        },
        {
          Component: AppointmentCheckPage,
          path: PATH.REPORTING.APPOINTMENT_CHECK,
        },
      ],
    },
  ],
  {
    /* Disables react router future flag warnings: https://reactrouter.com/en/6.28.0/upgrading/future#future-flags */
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);
