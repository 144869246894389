import { experteBlockungQuelle, ressourcenBlockungTypes } from '../../../../../../dtos';
import colorPalette from '../../../../../../theme/colorPalette';
import { getBackendConfig } from '../../../../../../utils/BackendInfrastructure';
import { type CalendarEvent, isExperteEvent } from '../helperClasses';
import { EventButton } from './EventButton';
import { ExternalSAPEvent } from './ExternalSAPEvent';
import { VivaEvent } from './VivaEvent';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type DisplayCalendarEventProps = {
  readonly event: CalendarEvent;
};

const isExternalEvent = (veranstaltungId: string | undefined): boolean => veranstaltungId === 'null';

const determineEventColor = (event: CalendarEvent): string => {
  if (event.isCurrentSelection) {
    return colorPalette.primary.main;
  }

  if (event.isOldSelection) {
    return colorPalette.monochrome.grey60;
  }

  if (isExternalEvent(event.data.veranstaltungId)) {
    return colorPalette.warning.main;
  }

  return colorPalette.colors.crimson;
};

export const DisplayCalendarEvent: React.FC<DisplayCalendarEventProps> = ({ event }: DisplayCalendarEventProps) => {
  const blockingData = event.data;
  const color = determineEventColor(event);

  const [hiveUrl, setHiveUrl] = useState('');
  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl ?? '');
      }
    };

    void setUrl();
  }, []);

  const gotoHive = (): void => {
    let link = '';
    if (isExperteEvent(event)) {
      const id = z.coerce.number().parse(event.dataId);
      link = `${hiveUrl}/experten/${id}/auslastung`;
    }

    if (!link) {
      return;
    }

    window.open(link, '_blank', 'noopener');
  };

  if (event.isCurrentSelection) {
    return (
      <EventButton eventColor={color}>
        <Typography variant="body2">Ausgewählt</Typography>
      </EventButton>
    );
  }

  if (event.isOldSelection) {
    return (
      <EventButton eventColor={color}>
        <Typography variant="body2">bisherige Auswahl</Typography>
      </EventButton>
    );
  }

  if (event.type === ressourcenBlockungTypes.STANDORT) {
    return <VivaEvent calendarEvent={event} color={color} />;
  }

  if (blockingData.quelle === experteBlockungQuelle.VAMOS && blockingData.veranstaltungId && !isExternalEvent(blockingData.veranstaltungId)) {
    return <VivaEvent calendarEvent={event} color={color} />;
  }

  if (isExternalEvent(blockingData.veranstaltungId)) {
    return <ExternalSAPEvent calendarEvent={event} color={color} />;
  }

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="body2">TerminId: {blockingData.veranstaltungId}</Typography>
          <Typography variant="body2">Start: {event.start.toLocaleString()}</Typography>
          <Typography variant="body2">Ende: {event.end.toLocaleString()}</Typography>
        </Stack>
      }
    >
      <Box>
        <EventButton eventColor={color} onClick={gotoHive}>
          <Stack>
            <Typography>{blockingData.division ?? 'kein Geschäftsbereich'}</Typography>
            <Typography variant="body2">{blockingData.quelle}</Typography>
          </Stack>
        </EventButton>
      </Box>
    </Tooltip>
  );
};
