import { ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type CalendarExperteEvent } from './CalendarExperteEvent';
import { type CalendarRaumEvent } from './CalendarRaumEvent';
import { type CalendarSlotEvent } from './CalendarSlotEvent';

export const isRaumEvent = (event: CalendarEvent): event is CalendarRaumEvent => event.type === ressourcenBlockungTypes.RAUM;

export const isExperteEvent = (event: CalendarEvent): event is CalendarExperteEvent => event.type === ressourcenBlockungTypes.EXPERTE;
export const isSlotEvent = (event: CalendarEvent): event is CalendarSlotEvent => event.type === ressourcenBlockungTypes.STANDORT;

export type CalendarEvent = CalendarExperteEvent | CalendarRaumEvent | CalendarSlotEvent;
