import { type RessourcenBlockungType } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { type CalendarEvent } from '../CalendarEvent';
import { type MbscResource } from '@mobiscroll/react';

export abstract class AbstractCalendarResource implements MbscResource {
  public abstract type: RessourcenBlockungType;

  public abstract id: string;

  public abstract name: string;

  public readonly terminInfo: Pick<TerminInfo, 'id' | 'sapid' | 'division' | 'ablauf'>;

  public constructor(terminInfo: TerminInfo) {
    this.terminInfo = terminInfo;
  }

  public abstract toEvents(): CalendarEvent[];

  public abstract getDataId(): number;

  public abstract createEvent(start: Date, end: Date): CalendarEvent;
}
