import { ressourcenBlockungTypes } from '../../../../../../dtos';
import { type CalendarExperteResource, type CalendarRaumResource, type CalendarResource, type CalendarSlotResource, isExperteResource, isRaumResource } from '../helperClasses';
import { Box, Tooltip, Typography } from '@mui/material';

type ResourceToolTipProps = {
  readonly resource: CalendarResource;
  readonly year: number;
};

const ResourceToolTip: React.FC<ResourceToolTipProps> = ({ resource, year }: ResourceToolTipProps) => {
  if (isRaumResource(resource)) {
    return (
      <Typography variant="caption">
        Region: {resource.data.regionName ?? '-'}
        <br />
        Standort: {resource.data.standortName ?? '-'}
        <br />
        Ort: {resource.data.ortName ?? '-'}
        <br />
        <br />
        Kapazität:
        <br />• U-Form: {resource.data.kapazitaet.uForm ?? '-'}
        <br />• Parlamentarisch: {resource.data.kapazitaet.parlamentarisch ?? '-'}
        <br />• ohne Tisch: {resource.data.kapazitaet.ohneTische ?? '-'}
        <br />
      </Typography>
    );
  }

  if (isExperteResource(resource)) {
    const workloadSelectedYear = resource.data.workload.find((wl) => wl.year === year);
    return (
      <Typography variant="caption">
        {workloadSelectedYear ? (
          <>
            Jahr: {workloadSelectedYear.year}
            <br />
            Gebuchte Tage: {Math.round(workloadSelectedYear.bookedDays)}
            <br />
            Vorgemerkte Tage: {Math.round(workloadSelectedYear.bookmarkedDays)}
            <br />
            Summe Tage: {Math.round(workloadSelectedYear.sumDays)}
            <br />
            Schwellwert gelb: {workloadSelectedYear.thresholdYellow}
            <br />
            Schwellwert rot: {workloadSelectedYear.thresholdRed}
            <br />
          </>
        ) : (
          <span>Keine Auslastungsdaten für das ausgewählte Kalenderjahr verfügbar. Bitte prüfe HIVE für Details!</span>
        )}
      </Typography>
    );
  }

  return undefined;
};

type ExperteResourceProps = {
  readonly resource: CalendarExperteResource;
  readonly hiveUrl: string;
};

const ExperteResource: React.FC<ExperteResourceProps> = (props: ExperteResourceProps) => (
  <a style={{ textDecoration: 'none', color: 'unset' }} href={`${props.hiveUrl}/experten/${props.resource.getDataId()}/auslastung`} target="_blank" rel="noreferrer">
    <Box>
      <Typography variant="body2">{props.resource.name}</Typography>
    </Box>
  </a>
);

type RaumResourceProps = {
  readonly resource: CalendarRaumResource;
};

const RaumResource: React.FC<RaumResourceProps> = (props: RaumResourceProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
  </Box>
);

type SlotResourceProps = {
  readonly resource: CalendarSlotResource;
};

const SlotResourceOrResourceGroup: React.FC<SlotResourceProps> = (props: SlotResourceProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
    <Typography variant="body2">{props.resource.subline}</Typography>
  </Box>
);

type CalendarResourceProps = {
  readonly resource: CalendarResource;
  readonly startTime: Date;
  readonly hiveUrl: string;
};

export const DisplayCalendarResource: React.FC<CalendarResourceProps> = (props: CalendarResourceProps) => {
  const { resource, startTime, hiveUrl } = props;

  const isNotExperteOrRaumResource = !isExperteResource(resource) && !isRaumResource(resource);

  return (
    <Tooltip
      title={
        (resource.type === ressourcenBlockungTypes.RAUM || resource.type === ressourcenBlockungTypes.EXPERTE) && (
          <ResourceToolTip resource={resource} year={startTime.getFullYear()} />
        )
      }
      placement="right"
      arrow
    >
      <Box sx={{ display: 'inline-block' }}>
        <>
          {isNotExperteOrRaumResource ? (
            <SlotResourceOrResourceGroup resource={resource} />
          ) : (
            <>
              {isExperteResource(resource) && <ExperteResource hiveUrl={hiveUrl} resource={resource} />}
              {isRaumResource(resource) && <RaumResource resource={resource} />}{' '}
            </>
          )}
        </>
      </Box>
    </Tooltip>
  );
};
