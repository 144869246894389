import { type ExperteFromResourcesRouter, ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarExperteEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { endOfDay, max, min, startOfDay } from 'date-fns';

export class CalendarExperteResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.EXPERTE;

  public readonly id: `t-${string}`;

  public name: string;

  public readonly data: ExperteFromResourcesRouter;

  public constructor(terminInfo: TerminInfo, data: ExperteFromResourcesRouter, year: number) {
    super(terminInfo);
    this.id = `t-${data.experteSapId}`;
    const workloadSelectedYear = data.workload.find((wl) => wl.year === year);
    const workloadSelectedYearString = workloadSelectedYear ? ` / (${Math.round(workloadSelectedYear.sumDays)} / ${workloadSelectedYear.thresholdRed})` : ' / ( - / - )';
    this.name = `${data.firstname} ${data.lastname}${workloadSelectedYearString}`;
    this.data = data;
  }

  public setName(name: string) {
    this.name = name;
  }

  public toEvents(): CalendarExperteEvent[] {
    const events: CalendarExperteEvent[] = [];

    for (const blockung of this.data.blockings) {
      const { startTime: start, endTime: end, veranstaltungId, division, quelle, quelleTerminId } = blockung;

      const existingEvent = events.find((ev) => ev.data.quelleTerminId === quelleTerminId);

      if (existingEvent) {
        existingEvent.start = min([existingEvent.start, startOfDay(start)]);
        existingEvent.end = max([existingEvent.end, endOfDay(end)]);
        existingEvent.data.ablauf.push({ start, end });
      } else {
        const newEvent = new CalendarExperteEvent(start, end, this, {
          ablauf: [],
          division,
          quelle,
          veranstaltungId,
          quelleTerminId,
        });
        newEvent.isOldSelection = veranstaltungId === String(this.terminInfo.id) || veranstaltungId === String(this.terminInfo.sapid);

        events.push(newEvent);
      }
    }

    return events;
  }

  public getDataId(): number {
    return this.data.experteSapId;
  }

  public createEvent(start: Date, end: Date): CalendarExperteEvent {
    const event = new CalendarExperteEvent(start, end, this);
    return event;
  }
}
