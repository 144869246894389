import { trpc } from '../../../../../../trpc';
import { formatTimeRangeForDate } from '../../../../../../utils/dateFormat';
import { type CalendarEvent, isSlotEvent } from '../helperClasses';
import { VeranstaltungEventButton } from './VeranstaltungEventButton';
import { Box, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material';

type VivaEventProps = {
  readonly calendarEvent: CalendarEvent;
  readonly color: string;
};

const veranstaltungQueryConfig = {
  retryOnMount: false,
  retry: false,
  refetchOnWindowFocus: false,
};

export const VivaEvent: React.FC<VivaEventProps> = ({ calendarEvent, color }: VivaEventProps) => {
  const vaHoverInfoQuery = trpc.veranstaltung.getHoverInfoByVeranstaltungId.useQuery({ id: Number(calendarEvent.data.veranstaltungId) }, veranstaltungQueryConfig);
  const vaProduktIdQuery = trpc.veranstaltung.getUrlInfoByVeranstaltungId.useQuery({ id: Number(calendarEvent.data.veranstaltungId) }, veranstaltungQueryConfig);

  if (vaHoverInfoQuery.isLoading || vaProduktIdQuery.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Tooltip
      title={
        vaHoverInfoQuery.isSuccess ? (
          <Stack>
            <Typography variant="body2">{vaHoverInfoQuery.data.title}</Typography>
            <Typography variant="body2">AKA-Veranstaltung-ID: {calendarEvent.data.quelleTerminId}</Typography>
            <Typography variant="body2">SapId: {vaHoverInfoQuery.data.sapId ?? '-'}</Typography>
            <Typography variant="body2">Geschäftsbereich: {vaHoverInfoQuery.data.geschaeftsbereich}</Typography>
            <Typography variant="body2">Ablaufzeiten:</Typography>
            {calendarEvent.data.ablauf.map(({ start, end }) => (
              <Typography key={Number(start)} variant="body2" paddingLeft={1}>
                {formatTimeRangeForDate(start, end)}
              </Typography>
            ))}
            {isSlotEvent(calendarEvent) && (
              <>
                <Divider />
                <Typography variant="body2">Gruppenraum: {calendarEvent.data.gruppenraum ? 'Ja' : 'Nein'}</Typography>
                <Typography variant="body2">Hotelqualifikation: {calendarEvent.data.hotelqualifikationen}</Typography>
                <Typography variant="body2">Raumgröße: {calendarEvent.data.raumgroesse}</Typography>
              </>
            )}
          </Stack>
        ) : (
          <Typography>Fehler beim Laden der Veranstaltung</Typography>
        )
      }
    >
      <Box>
        <VeranstaltungEventButton eventColor={color} themaSapId={vaProduktIdQuery.data?.produktid} veranstaltungId={calendarEvent.data.veranstaltungId}>
          {vaHoverInfoQuery.isSuccess ? (
            <Stack>
              <Typography variant="body2" textAlign="left">
                {vaHoverInfoQuery.data.buchungsnummer}
              </Typography>
              <Typography variant="caption" fontSize=".5rem" textAlign="center">
                {isSlotEvent(calendarEvent) ? calendarEvent.data.standortName : vaHoverInfoQuery.data.ortKuerzel}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2">Buchung</Typography>
          )}
        </VeranstaltungEventButton>
      </Box>
    </Tooltip>
  );
};
